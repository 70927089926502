import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import { Section, Container, Content } from "@atoms/layout"
import Layout from "@utils/layout"

const PrivacyPage = ({ data }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsPrivacyPolicy.seoMetaTags}/>
      <Section lgTop xl data-datocms-noindex>
        <Container containerLarge>
          <Content
            dangerouslySetInnerHTML={{
              __html: data.datoCmsPrivacyPolicy.content,
            }}
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default PrivacyPage

export const fetchPrivacy = graphql`
  query fetchPrivacy {
    datoCmsPrivacyPolicy {
      id
      content
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
